export const allCurrencies = [
  { symbol: "EUR", code: "€" },
  { symbol: "JPY", code: "¥" },
  { symbol: "USD", code: "$" },
  { symbol: "GBP", code: "£" },
  { symbol: "AUD", code: "A$" },
  { symbol: "CAD", code: "C$" },
  { symbol: "CHF", code: "Fr" },
  { symbol: "CNY", code: "¥" },
  { symbol: "INR", code: "₹" },
  { symbol: "AED", code: "د.إ" },
  { symbol: "HKD", code: "HK$" },
  { symbol: "NZD", code: "NZ$" },
  { symbol: "SGD", code: "S$" },
  { symbol: "KRW", code: "₩" },
  { symbol: "RUB", code: "₽" },
  { symbol: "BRL", code: "R$" },
  { symbol: "MXN", code: "Mex$" },
  { symbol: "ZAR", code: "R" },
  { symbol: "SEK", code: "kr" },
  { symbol: "NOK", code: "kr" },
  { symbol: "TRY", code: "₺" },
  { symbol: "ARS", code: "$" },
  { symbol: "CLP", code: "$" },
  { symbol: "EGP", code: "ج.م" },
  { symbol: "ILS", code: "₪" },
  { symbol: "KWD", code: "د.ك" },
  { symbol: "LKR", code: "රු" },
  { symbol: "NGN", code: "₦" },
  { symbol: "PEN", code: "S/." },
  { symbol: "SAR", code: "﷼" },
  { symbol: "VND", code: "₫" },
  { symbol: "CZK", code: "Kč" },
  { symbol: "HUF", code: "Ft" },
  { symbol: "PLN", code: "zł" },
  { symbol: "THB", code: "฿" },
  { symbol: "IDR", code: "Rp" },
  { symbol: "MYR", code: "RM" },
  { symbol: "PHP", code: "₱" },
]
